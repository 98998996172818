import React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

function OrderSuccess(props) {
  return (
    <Layout>
      <div className="container d-flex flex-column justify-content-center pt-5 mt-n6" style={{ flex: "1 0 auto" }}>
        <div className="pt-7 pb-5">
          <div className="text-center mb-2 pb-4">
            <StaticImage src="../images/order-confirmed.png" alt="Order confirmed" width={500} />
            <h2>
              Your reservation has been confirmed!
            </h2>
            <p className="pb-2">
              I'll be in contact shortly with the next steps!
            </p>
            <Link to={"/"} className="btn btn-translucent-primary me-3">
              Go back
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default OrderSuccess
